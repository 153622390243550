<template>
  <b-container class="padTandB">
    <b-row>
      <b-col class="hero">
        <div class="contact">
          <h1 class="heroTitle">Get in touch! We'd love to hear your feedback, suggestions and eco wisdom.</h1>
          <div>
            <p>Email: <a href="mailto:keepusingit.com@gmail.com">keepusingit.com@gmail.com</a></p>
            <ul>
              <a href="https://www.facebook.com/keepusingit" target="_blank"><li class="facebook"></li></a>
              <a href="https://twitter.com/keepusingit" target="_blank"><li class="twitter"></li></a>
            </ul>
            <!-- <b-form id="contact-form"  @submit.prevent="sendEmail" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-1"
                label="Email address:"
                label-for="input-1"
                description="We'll never share your email with anyone else."
              >
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  name="email"
                  required
                  placeholder="Enter email"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.name"
                  name="name"
                  required
                  placeholder="Enter name"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" label="message:" label-for="input-3">
                <b-form-textarea
                  id="textarea"
                  name="message"
                  v-model="form.message"
                  required
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
              <div id="success">
                Thank you, your email was sent successfully!
              </div>
              <div id="error">
                Oops, something went wrong. Please try again!
              </div>
              <b-button class="hero-cta2" type="submit" variant="primary">Submit</b-button>
              <b-button class="hero-ctaReset" type="reset" variant="danger">Reset</b-button>
            </b-form> -->
        </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import emailjs from 'emailjs-com';

export default {
  // data() {
  //   return {
  //     form: {
  //       email: '',
  //       name: '',
  //       message: ''
  //     },
  //     show: true,
  //     result: false
  //   }
  // },
  // methods: {
  //   sendEmail: (e) => {
  //     emailjs.sendForm('service_kjd6vta', 'template_1ua9n3m', e.target, 'user_UitB8e8xomUhRyIhQ98Pf')
  //       .then((result) => {
  //           console.log('SUCCESS!', result.status, result.text);
  //           var element = document.getElementById("success");
  //           element.classList.add("successAnim");
  //       }, (error) => {
  //           console.log('FAILED...', error);
  //           var element = document.getElementById("error");
  //           element.classList.add("successAnim");
  //       });
  //   },
  //   onReset(evt) {
  //     evt.preventDefault()
  //     // Reset our form values
  //     this.form.email = ''
  //     this.form.name = ''
  //     this.form.message = ''
  //     // Trick to reset/clear native browser form validation state
  //     this.show = false
  //     this.$nextTick(() => {
  //       this.show = true
  //     })
  //   }
  // },
  // mounted() {
  //   document.documentElement.style.scrollBehavior = "unset";
  // }
}
</script>
<style lang="scss" scoped>
// #success, #error {
//   opacity: 0;
// }
// .successAnim{
//     -webkit-animation: 5s ease 0s normal forwards 1 fadein;
//     animation: 5s ease 0s normal forwards 1 fadein;
// }
// @keyframes fadein{
//     0% { opacity:0; }
//     20% { opacity:1; }
//     100% { opacity:0; }
// }
// @-webkit-keyframes fadein{
//     0% { opacity:0; }
//     20% { opacity:1; }
//     100% { opacity:0; }
// }
.contact {
  // form {
  //   text-align: left;
  //   padding-top: 50px;
  //   .hero-ctaReset {
  //     font-size: 22px;
  //     background-color: transparent;
  //     color: #C82333;
  //     text-decoration: none;
  //     border: 2px solid #C82333;
  //     border-radius: 0;
  //     display: inline-block;
  //     padding: 10px 20px;
  //     margin: 20px 0;
  //     &:hover {
  //       background-color: #C82333;
  //       border: 2px solid #C82333;
  //       color: #fff;
  //     }
  //   }
  // }
  p {
    text-align: left;
  }
  ul {
      padding: 0;
      text-align: left;
      li {
        display: inline-block;
        width: 40px;
        height: 40px;
      }
      .facebook {
        background: url('../img/icons/facebook-icon-share.png');
        background-size: cover;
        margin-right: 5px;
        &:hover {
          opacity: 0.8;
        }
      }
      .twitter {
        background: url('../img/icons/twitter-icon-share.png');
        background-size: cover;
        margin-left: 5px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
}
</style>
