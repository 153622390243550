<template>
  <b-container fluid id="vidCont">
    <b-container class="padTandB">
      <b-row>
        <b-col class="video">
          <div class="vidOuter">
            <h1 class="heroTitle">Watch this video from the wwf on plastic pollution</h1>
            <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/IA9O9YUbQew?rel=0"
              allowfullscreen
            ></b-embed>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
  export default {
    mounted() {
      document.documentElement.style.scrollBehavior = "unset";
    }
  }
</script>

<style lang="scss" scoped>
  #navBar {
    background-color: rgba(4, 30, 41, 0.9) !important;
  }
  #vidCont {
    background-color: #187B98;
    color: white;
    .vidOuter {
      padding: 0 10%;
    }
  }
</style>
