<template>
  <b-container>
    <b-row class="title">
       <b-col>
         <h1 class="heroTitle">Latest news about plastic pollution</h1>
       </b-col>
     </b-row>
     <b-row class="article"  v-for="(article, index) in articles" :key="index" @click="navTo(article.url)">
      <b-col class="header" sm="12" md="3">
        <img v-if="article.image" :src="article.image" alt="">
      </b-col>
      <b-col class="section" sm="12" md="9">
        <p class="sectionTitle" v-html="article.title"></p>
        <p class="source" v-html="article.source.name"></p>
      </b-col>
    </b-row>
  </b-container>
  <!-- <div class="container">
    <h1>Latest news about plastic pollution from around the globe</h1>
    <div class="result-list">
      <div v-for="(article, index) in articles" :key="index" @click="navTo(article.url)">
        <header>
          <img v-if="article.image" :src="article.image" alt="">
        </header>
        <section>
          <p v-html="article.title"></p>
          <p class="source" v-html="article.source.name"></p>
        </section>
      </div>
    </div>
  </div> -->
</template>

<script>
  export default {
    props: [
      'apiKey'
    ],
    data: () => {
      return {
        apiUrl: '',
        isBusy: false,
        showloader: false,
        currentPage: 1,
        totalResults: 0,
        maxPerPage: 20,
        searchword: '',
        articles: []
        // country: 'us'
      }
    },
    computed: {
      pageCount() {
        return Math.ceil(this.totalResults/this.maxPerPage);
      }
    },
    methods: {
      navTo(url) {
        window.open(url);
      },
      resetData() {
        this.currentPage = 1;
        this.articles = [];
      },
      fetchTopNews() {
        this.apiUrl = 'https://gnews.io/api/v4/search?q=plastic+pollution'  +
                        '&token=' + this.apiKey;
        this.isBusy = true;
        this.searchword = '';

        this.resetData();
        this.fetchData();
      },
      fetchData() {
        let req  = new Request(this.apiUrl + '&page=' + this.currentPage);
        fetch(req)
          .then((resp) => resp.json())
          .then((data) => {
            this.totalResults = data.totalResults;
            data.articles.forEach(element => {
              this.articles.push(element);
            });
            this.isBusy = false;
            this.showloader = false;
          })
          .catch((error) => {
            console.log(error);
          })
      }
    },
    created() {
      this.fetchTopNews();
    }
  }
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  .title {
    border-bottom: 1px solid #ccc;
  }
  .heroTitle {
    padding-bottom: 90px !important;
  }
  .result-list {
    padding-top: 60px;
  }
  .article {
    border-bottom: 1px solid #ccc;
    overflow: hidden;
    text-align: left;
    height: 150px;
    cursor: pointer;
    .header {
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 0;
      img {
        float: left;
        width:  100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .section {
      margin: 0;
      padding: 15px;
      height: 100%;
      position:relative;
      .source {
        color: #187B98;
        position:absolute;
        bottom: 10px;
      }
      p {
        margin: 0;
      }
    }
  }
}
@media (max-width: 767px) {
  .container .article {
    border: 1px solid #ccc;
    padding-bottom: 20px;
    margin: 20px;
    height: auto;
  }
  .container .article .section .source {
    bottom: 0px;
  }
  .sectionTitle {
    padding-bottom: 20px;
  }
  .container .heroTitle {
    padding-bottom: 70px !important;
  }
  .container .title {
    border-bottom: none;
  }
}
</style>
