<template>
  <b-container class="padTandB">
    <b-row class="justify-content-center">
      <b-col md="auto">
        <div class="blog">
          <h1 class="heroTitle">Blogs about sustainable living</h1>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center pb">
      <b-col lg="5">
        <div>
          <b-link to="/behind-the-chicken-shed">
            <b-card
                title="Behind the chicken shed"
                img-src="../img/blogPics/btcs/chicken-shed2.jpg"
                img-alt="Greenhouse in the countryside"
                img-top
                tag="article"
                style=""
                class="mb-2"
              >
              <b-card-text>
                A blog by Charity Reed
              </b-card-text>

              <b-button class="hero-cta2" href="/behind-the-chicken-shed" variant="primary">Read blog</b-button>
            </b-card>
          </b-link>
        </div>
      </b-col>
      <b-col lg="5">
        <div>
          <b-link to="/useful-tips-and-ideas-for-sustainable-living">
            <b-card
                title="Useful tips & ideas"
                img-src="../img/blogPics/tips/eco-tiny-moss-house.jpg"
                img-alt="Greenhouse in the countryside"
                img-top
                tag="article"
                style=""
                class="mb-2"
              >
              <b-card-text>
                News, stories, tips and advice.
              </b-card-text>

              <b-button class="hero-cta2" href="/useful-tips-and-ideas-for-sustainable-living" variant="primary">Read blog</b-button>
            </b-card>
          </b-link>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  mounted() {
    document.documentElement.style.scrollBehavior = "unset";
  }
}

</script>


<style lang="scss" scoped>
  .pb {
    a {
      color: #000;
      &:hover {
        .card-img-top {
          filter: sepia(100%);
        }
        .hero-cta2 {
          color: #fff !important;
          background-color: #0098aa !important;
        }
      }
      .card {
        margin: 50px auto;
        float: none;
      }
    }
  }

</style>
