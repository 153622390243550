<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col md="auto">
        <div class="blog">
          <h1 class="heroTitle">We are all streaming, but where does this stream go?</h1>
          <p>by Jesse Reed</p>
          <div class="page-content">
            <p><i>Published on 18 November 2022</i></p>
            <p>I am a tech skeptic. I miss the good old days of landlines and paper. The world is pushing me towards technologies that I don't only wish not to use but also don't entirely trust. I know that some of the feelings I have about technology are a bit extreme and probably unrealistic, but I think many of us have a degree of apprehension when it comes to the topic of technology, in particular the internet.
              <br><br>
              I recently moved from Italy to the Netherlands, and my children are experiencing the tough transition from one school system to another and from one set of friends to another. The thing that has surprised me is the difference in terms of the schools' relationship to technology. In Italy, they barely used it at all; the day consisted mainly of traditional exercises using pen and paper. The Netherlands, on the other hand, has embraced tech with arms open wide, and my children now spend much of the day in front of one type of screen or another. Is this a good thing or a bad thing?
              <br><br>
              I could base this blog on the emotional impact excessive screen time has on us all, but I am not a psychologist. I am someone who has worked in the tech industry (albeit slightly reluctantly) for a number of years and who has an interest in the environmental impact of the internet. Is the exponential growth of screen time destroying the planet?
              <br><br>
              According to the <a href="https://www.theguardian.com/tv-and-radio/2021/oct/29/streamings-dirty-secret-how-viewing-netflix-top-10-creates-vast-quantity-of-co2" target="_blank">Guardian newspaper</a>, Youtube has a yearly CO2 output equivalent to the entire city of Glasgow. These figures came from a report released by Bristol University in a year when YouTube had 1.4 billion users. It now has well over 2 billion users, so the amount of greenhouse gas emitted is obviously higher. What does this mean without context, though? How does it compare to the CO2 output from other companies? Is this even a high figure when you consider there are 1.4 billion users and it is the output for an entire year?
              <br><br>
              Industry-backed research from the <a href="https://www.carbontrust.com/resources/carbon-impact-of-video-streaming" target="_blank">Carbon Trust</a> indicates that 1 hour of streaming Netflix emits about 55 grams of carbon dioxide into the atmosphere. This was good news for the industry, as it meant that the impact was lower than previously thought. Netfix plans to reach net zero by the end of 2022 and encourage their partners to make cleaner devices and their customers to switch to so-called "green tariffs," according to Emma Stewart, the head of sustainability at Netflix.
              <br><br>
              According to the <a href="https://theshiftproject.org/en/article/unsustainable-use-online-video/" target="_blank">Shift Project</a>, digital is on track to account for 7% of all global CO2 emissions by 2025; that's almost as much as all cars do today. They claim, “We live in a world where only one form of digital use, online video, generates 60% of world data flows and thus over 300 million tons of co2 per year. This use is far from being “dematerialized”. On the contrary, it represents 20% of the greenhouse gas emissions of all digital devices (use and production included), and 1% of global emissions, i.e. as much as Spain.”</p>
              <b-img src="../../img/blogPics/tips/shiftGraphic.png" fluid-grow alt="Fluid-grow image"></b-img>
              <br><br>
              <p>Companies like Netflix are trying to make their technologies cleaner in order to reduce their carbon footprint. The thing is, the growth of streaming is exponential at the moment. I can see it from my own house. The introduction of a smart TV massively increased our usage of services like YouTube and Netflix. It is incredibly addictive, and my wife and I have a constant battle to try and keep our children and ourselves away from a screen.
              <br><br>
                That's the thing; the industry can control what technology they use and how efficient it is, but they can't control the people who use it. Our usage is the terrifying part of all this; YouTube reached 2.5 billion active users in Q2 2021, and the upward growth shows no sign of stopping. And that doesn't even factor in how environmentally friendly the devices are that we watch it on, how often we upgrade our devices, or what kind of energy provider we use to power them. It doesn't take a huge amount of common sense to realize this kind of growth is unsustainable.</p>
              <b-img src="../../img/blogPics/tips/youtubeUsers.jpg" fluid-grow alt="Fluid-grow image"></b-img>
              <br><br>
              <p>So with whom does the responsibility lie? With us? We have a choice to watch or not to watch. With the industry? After all, they don't exactly encourage us to watch less, do they? Well, I guess the answer is both, and I think understanding that there is a problem in the first place is a good starting point for a solution. Look at the tobacco industry; there weren't always images of dying people on the packages. I can feel the addiction to streaming, and I am an ex-smoker, so I can literally feel the similarities of the withdrawal symptoms.
              <br><br>
              I don't know the solution, but I do know that multi-billion dollar tech companies have motivations that are probably more important to them than CO2. Therefore, it is up to us. We have found ways to give up smoking and clean our lungs; now it's time to reduce streaming and clean the planet.</p>
              <b-button class="hero-cta2" to="/blog" variant="primary">Main blog</b-button>
              <b-button class="hero-cta2" to="/useful-tips-and-ideas-for-sustainable-living" variant="primary">Useful tips & ideas</b-button>
          </div>
          <ul>
            <li>
              <ShareNetwork
              class="socialIcon facebookShare"
              network="facebook"
              url="https://keepusingit.com/we-are-all-streaming-but-where-does-this-stream-go"
              title="We are all streaming, but where is this stream going?"
              description="I think many of us have a degree of apprehension when it comes to the topic of technology, in particular the internet. How damaging is streaming, and what will the future look like?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon twitterShare"
              network="twitter"
              url="https://keepusingit.com/we-are-all-streaming-but-where-does-this-stream-go"
              title="We are all streaming, but where is this stream going?"
              description="I think many of us have a degree of apprehension when it comes to the topic of technology, in particular the internet. How damaging is streaming, and what will the future look like?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon linkedinShare"
              network="linkedin"
              url="https://keepusingit.com/we-are-all-streaming-but-where-does-this-stream-go"
              title="We are all streaming, but where is this stream going?"
              description="I think many of us have a degree of apprehension when it comes to the topic of technology, in particular the internet. How damaging is streaming, and what will the future look like?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon whatsappShare"
              network="whatsapp"
              url="https://keepusingit.com/we-are-all-streaming-but-where-does-this-stream-go"
              title="We are all streaming, but where is this stream going?"
              description="I think many of us have a degree of apprehension when it comes to the topic of technology, in particular the internet. How damaging is streaming, and what will the future look like?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {

}

</script>


<style lang="scss" scoped>
  .col-md-auto {
    width: auto;
    .blog {
      padding: 150px 0 100px 0;
      .page-content {
        padding-top: 30px;
        .hero-cta2 {
          margin-right: 10px !important;
        }
      }
      ul {
        text-align: left;
        padding: 20px 0 0 0;
        li {
          display: inline-block;
          padding-right: 10px;
          .socialIcon {
            width: 50px;
            height: 50px;
            display:block;
            &:hover {
              opacity: 0.8;
            }
          }
          .facebookShare {
            background: url('../../img/icons/facebook-icon-share.png');
            background-size: cover;
          }
          .twitterShare {
            background: url('../../img/icons/twitter-icon-share.png');
            background-size: cover;
          }
          .linkedinShare {
            background: url('../../img/icons/linkedin-icon-share.png');
            background-size: cover;
          }
          .whatsappShare {
            background: url('../../img/icons/whatsapp-icon-share.png');
            background-size: cover;
          }
        }
      }
    }
  }
</style>
