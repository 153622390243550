<template>
  <div id="news" class="padTandB">
    <news :apiKey="apiKey"> </news>
  </div>
</template>

<script>
import newsSection from '@/components/newsSection.vue';
export default {
  data(){
    return {
      apiKey: '758f4972fc0eb450c0f5006be36a0355' // You need an API Key from newsapi.org
    }
  },
  components: {
    "news" : newsSection
  }
}
</script>

<style lang="scss">

</style>
