<template>
   <b-container class="blogMain">
     <b-row>
      <b-col>
        <h1 class="heroTitle">Useful tips & ideas</h1>
      </b-col>
     </b-row>
     <b-row>
       <!-- Blog Entries Column -->
       <b-col sm="12" md="6" class="posts" :key="index" v-for="(item, index) in paginatedItems">

         <!-- Blog Post -->
         <div class="overflow-auto">
          <div >
            <b-link :to="item.href">
              <b-card
                :title="item.title"
                :img-src="item.src"
                :img-alt="item.alt"
                img-top
                text-variant="black"
                tag="article"
                style=""
                class="mb-2"
                >
                <p class="card-text">
                  {{item.body}}
                </p>
                <b-button class="hero-cta2" :to="item.href" variant="primary">Read blog</b-button>
              </b-card>
            </b-link>
          </div>
          <!-- Pagination -->
            <!-- <b-pagination
                @change="onPageChanged"
                :total-rows="totalRows"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0"
            /> -->
        </div>

       </b-col>
     </b-row>
     <!-- <b-row>
       <b-col>
         <b-pagination
             @change="onPageChanged"
             :total-rows="totalRows"
             :per-page="perPage"
             v-model="currentPage"
             class="my-0"
         />
       </b-col>
     </b-row> -->
     <b-row>
       <!-- Sidebar Widgets Column -->

       <b-col md="12" class="catSearch">
         <!-- Search Widget -->
         <div>
           <b-card header="Categories">
            <b-link to="/blog" class="card-link">Main blog page</b-link>
            <b-link to="/behind-the-chicken-shed" class="card-link">Behind the chicken shed</b-link>
          </b-card>
        </div>
        </b-col>
        <!-- <b-col sm="12" md="6">
        <div class="searchSection">
          <b-card header="Search">
            <b-input v-model="searchTerm" type="text"></b-input>
            <div></div>
            <div  v-for="blogItem in filterByTerm" :key="blogItem.id">
              <b-link v-if="searchTerm" :to="blogItem.href">{{ blogItem.name }}</b-link>
            </div>
         </b-card>
       </div>
       </b-col> -->
     </b-row>
   </b-container>
</template>

<script>

const items = [
  {
    title: "We are all streaming, but where does this stream go?",
    body: "I am a tech skeptic. I miss the good old days of landlines and paper. The world is pushing me towards technologies that I don't only wish not to use but also don't entirely trust.",
    src: require("../img/cardPics/streaming.jpg"),
    alt: "Plastic pollution on beach in China",
    href: "/we-are-all-streaming-but-where-does-this-stream-go"
  },
  {
    title: "How does plastic get in to the ocean anyway?",
    body: "We know that plastic pollution is a big problem, and we know that not enough is being done about it, but where is all this plastic coming from? And what can we do to help?",
    src: require("../img/cardPics/plastic-on-beach-china.jpg"),
    alt: "Plastic pollution on beach in China",
    href: "/how-does-plastic-get-in-to-the-ocean-anyway"
  },
  {
    title: "Clothes that don't kill fish",
    body: "With 40 microplastic particles per cubic metre of water, and much of that coming from our clothes. Here are some vegan and plastic free clothing brands, so we can shop with a clear conscience.",
    src: require("../img/cardPics/ethletic-card.jpg"),
    alt: "Black sports shoes",
    href: "/clothes-that-dont-kill-fish"
  },
  {
    title: "You have a voice",
    body: "If someone had said to you a few years ago, that the protest of a 15 year old girl outside of the Swedish Parliament would turn in to a global political movement, would you have believed it?",
    src: require("../img/cardPics/boy-shouting-in-mic-card.jpg"),
    alt: "Boy shouting in to microphone",
    href: "/you-have-a-voice"
  }
];
export default {
  name: "MyBootstrapGrid",
  data() {
    return {
      blogList: [
        { name: "You have a voice", href: "/you-have-a-voice" }
      ],
      searchTerm: "",
      filter: '',
      fields: ['title'],
      items: items,
      paginatedItems: items,
      perPage: 4,
      totalRows: items.length,
      currentPage: 1
    };
  },
  computed: {
    pageCount() {
      let l = this.totalRows,
        s = this.perPage;
      return Math.floor(l / s);
    },
    filterByTerm() {
      return this.blogList.filter(blogItem => {
        return blogItem.name.toLowerCase().includes(this.searchTerm);
      });
    }
  },
  mounted(){
    this.paginate(this.perPage, 0)
  },
  methods: {
    paginate (page_size, page_number) {
      let itemsToParse = this.items
      this.paginatedItems = itemsToParse.slice(page_number * page_size, (page_number + 1) * page_size);
    },
    onPageChanged(page){
      this.paginate(this.perPage, page - 1)
    }
  }
}

</script>

<style lang="scss" scoped>
.blogMain {
  padding-top: 150px;
  padding-bottom: 100px;
  a {
    color: #212529;
    &:hover {
      .card-img-top {
        filter: sepia(100%);
      }
      .hero-cta2 {
        color: #fff !important;
        background-color: #0098aa !important;
      }
    }
  }
  .card {
    margin: 50px auto;
    float: none;
    .card-link {
      @media (max-width: 991px) {
        display:block;
        margin-left: 0;
      }
      @media (max-width: 767px) {
        display:inline-block;
      }
      @media (max-width: 390px) {
        display:block;
        margin-left: 0 !important;
      }
      +.card-link {
        @media (max-width: 767px) {
          margin-left: 1.25rem;
        }
      }
    }
  }
  .catSearch {
    a {
      &:hover {
        opacity: 0.8;
      }
    }
    .searchSection {
      .card-body {
        text-align: left;
        a {
          margin: 5px 0;
          display: block;
        }
        .form-control {
          margin-bottom: 20px;
        }
      }
      @media (max-width: 767px) {
        display:none;
      }
    }
  }

}
</style>
