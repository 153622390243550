<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col md="auto">
        <div class="blog">
          <h1 class="heroTitle">You have a voice</h1>
          <p>by Jesse Reed</p>
          <div class="page-content">
            <p><i>Published on 2 December 2020</i></p>
            <p>What’s the point of saying anything anyway? It won’t change a thing! Or will it? I mean, if someone had said to you a few years ago that the protest of a 15-year-old girl outside of the Swedish Parliament would turn into a global political movement, would you have believed it?
              <br><br>
              I am not suggesting we all become world-famous climate activists; I am simply saying that a single voice can be powerful. Greta Thunberg isn’t a superhero; she’s just a person who saw something she didn’t like and had the courage to say it.
              <br><br>
              So what can we do? What can we say? And who do we say it to? What about writing a letter to a company that we are frustrated with? I, for example, love Marmite. I have eaten buckets of the stuff throughout my life. A few years ago, I noticed the glass jars being replaced by plastic ones, and it really annoyed me. Did I say anything? No, but I should have!
              <br><br>
              When we go to a shop and we see them putting our order in plastic. We can say “Excuse me, can you please find another packaging material because I am against the use of single use plastic” It is not only powerful for you but also good for the planet. It is also a strong message to anyone who happens to hear you.
              <br><br>
              In the town of Keynsham near Bristol, UK, a small group of frustrated shoppers had simply had enough of single-use plastic and they mounted a <a href="https://plasticattack.co.uk/" target="_blank">plastic attack</a> on their local Tesco supermarket. They bought their groceries, and then after paying at the till, they unwrapped all of the food, put it inside reusable containers, and told the supermarket they were leaving all the packaging behind for Tesco to deal with. The event was filmed, word got out, and soon plastic attacks started happening all over the world.</p>
              <br>
              <b-img src="../../img/blogPics/tips/plastic-attack-ibiza.jpg" fluid-grow alt="Fluid-grow image"></b-img>
              <br><br>
              <p>OK, a plastic attack might seem a bit extreme, but how about this? Next time your child has a birthday party, contact everyone invited and ask them nicely not to bring presents made of plastic. It is a nice way to introduce a conversation about plastic to your friends and family, and you will end up with less plastic in your house. win win!
              <br><br>
                So I guess what I’m trying to say is, "Don’t be shy!" Be proud of your beliefs against plastic, and don’t be afraid to let someone know you don’t want their plastic!</p>
              <br>
              <b-button class="hero-cta2" to="/blog" variant="primary">Main blog</b-button>
              <b-button class="hero-cta2" to="/useful-tips-and-ideas-for-sustainable-living" variant="primary">Useful tips & ideas</b-button>
          </div>
          <ul>
            <li>
              <ShareNetwork
              class="socialIcon facebookShare"
              network="facebook"
              url="https://keepusingit.com/you-have-a-voice"
              title="You have a voice"
              description="What’s the point of saying anything anyway? it won’t change a thing! or will it? I mean if someone had said to you a few years ago, that the protest of a 15 year old girl outside of the Swedish Parliament would turn in to a global political movement, would you have believed it?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon twitterShare"
              network="twitter"
              url="https://keepusingit.com/you-have-a-voice"
              title="You have a voice"
              description="What’s the point of saying anything anyway? it won’t change a thing! or will it? I mean if someone had said to you a few years ago, that the protest of a 15 year old girl outside of the Swedish Parliament would turn in to a global political movement, would you have believed it?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon linkedinShare"
              network="linkedin"
              url="https://keepusingit.com/you-have-a-voice"
              title="You have a voice"
              description="What’s the point of saying anything anyway? it won’t change a thing! or will it? I mean if someone had said to you a few years ago, that the protest of a 15 year old girl outside of the Swedish Parliament would turn in to a global political movement, would you have believed it?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon whatsappShare"
              network="whatsapp"
              url="https://keepusingit.com/you-have-a-voice"
              title="You have a voice"
              description="What’s the point of saying anything anyway? it won’t change a thing! or will it? I mean if someone had said to you a few years ago, that the protest of a 15 year old girl outside of the Swedish Parliament would turn in to a global political movement, would you have believed it?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {

}

</script>


<style lang="scss" scoped>
  .col-md-auto {
    width: auto;
    .blog {
      padding: 150px 0 100px 0;
      .page-content {
        padding-top: 30px;
        .hero-cta2 {
          margin-right: 10px !important;
        }
      }
      ul {
        text-align: left;
        padding: 20px 0 0 0;
        li {
          display: inline-block;
          padding-right: 10px;
          .socialIcon {
            width: 50px;
            height: 50px;
            display:block;
            &:hover {
              opacity: 0.8;
            }
          }
          .facebookShare {
            background: url('../../img/icons/facebook-icon-share.png');
            background-size: cover;
          }
          .twitterShare {
            background: url('../../img/icons/twitter-icon-share.png');
            background-size: cover;
          }
          .linkedinShare {
            background: url('../../img/icons/linkedin-icon-share.png');
            background-size: cover;
          }
          .whatsappShare {
            background: url('../../img/icons/whatsapp-icon-share.png');
            background-size: cover;
          }
        }
      }
    }
  }
</style>
