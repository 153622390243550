<template>
   <b-container class="blogMain">
     <b-row>
      <b-col>
        <h1 class="heroTitle">Behind the chicken shed</h1>
        <p>A blog by Charity Reed</p>
      </b-col>
     </b-row>
     <b-row>
       <!-- Blog Entries Column -->
       <b-col sm="12" md="6" class="posts" :key="index" v-for="(item, index) in paginatedItems">

         <!-- Blog Post -->
         <div class="overflow-auto">
          <div >
            <b-link :to="item.href">
              <b-card
                :title="item.title"
                :img-src="item.src"
                :img-alt="item.alt"
                img-top
                text-variant="black"
                tag="article"
                style=""
                class="mb-2"
                >
                <p class="card-text">
                  {{item.body}}
                </p>
                <b-button class="hero-cta2" :to="item.href" variant="primary">Read blog</b-button>
              </b-card>
            </b-link>
          </div>
          <!-- Pagination -->
            <!-- <b-pagination
                @change="onPageChanged"
                :total-rows="totalRows"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0"
            /> -->
        </div>

       </b-col>
     </b-row>
     <!-- <b-row>
       <b-col>
         <b-pagination
             @change="onPageChanged"
             :total-rows="totalRows"
             :per-page="perPage"
             v-model="currentPage"
             class="my-0"
         />
       </b-col>
     </b-row> -->
     <b-row>
       <!-- Sidebar Widgets Column -->

       <b-col sm="12" md="12" class="catSearch">
         <!-- Search Widget -->
         <div>
           <b-card header="Categories">
            <b-link to="/blog" class="card-link">Main blog page</b-link>
            <b-link to="/useful-tips-and-ideas-for-sustainable-living" class="card-link">Useful tips and ideas</b-link>
          </b-card>
        </div>
        </b-col>
        <!-- <b-col sm="12" md="6">
        <div class="searchSection">
          <b-card header="Search">
            <b-input v-model="searchTerm" type="text"></b-input>
            <div></div>
            <div  v-for="blogItem in filterByTerm" :key="blogItem.id">
              <b-link v-if="searchTerm" :to="blogItem.href">{{ blogItem.name }}</b-link>
            </div>
         </b-card>
       </div>
       </b-col> -->
     </b-row>
   </b-container>
</template>

<script>

const items = [
  {
    title: "What is normal anyway?",
    body: "We’ve probably all heard the great news that Covid-19 has been a “green event” with fewer planes in the skies, and fewer cars...",
    src: require("../img/cardPics/woman-child-planting-tree-card.jpg"),
    alt: "Woman and child planting a tree",
    href: "/what-is-normal-anyway"
  },
  {
    title: "A little victory every time",
    body: "I’ve always enjoyed recycling old stuff and making it into something better, probably due to my personal place in history and society...",
    src: require("../img/cardPics/greenhouse-card.jpg"),
    alt: "Greenhouse in the countryside",
    href: "/a-little-victory-every-time"
  }
];
export default {
  name: "MyBootstrapGrid",
  data() {
    return {
      blogList: [
        { name: "What is normal anyway?", href: "/what-is-normal-anyway" },
        { name: "A little victory every time", href: "/a-little-victory-every-time" }
      ],
      searchTerm: "",
      filter: '',
      fields: ['title'],
      items: items,
      paginatedItems: items,
      perPage: 2,
      totalRows: items.length,
      currentPage: 1
    };
  },
  computed: {
    pageCount() {
      let l = this.totalRows,
        s = this.perPage;
      return Math.floor(l / s);
    },
    filterByTerm() {
      return this.blogList.filter(blogItem => {
        return blogItem.name.toLowerCase().includes(this.searchTerm);
      });
    }
  },
  mounted(){
    this.paginate(this.perPage, 0)
  },
  methods: {
    paginate (page_size, page_number) {
      let itemsToParse = this.items
      this.paginatedItems = itemsToParse.slice(page_number * page_size, (page_number + 1) * page_size);
    },
    onPageChanged(page){
      this.paginate(this.perPage, page - 1)
    }
  }
}

</script>

<style lang="scss" scoped>
.blogMain {
  padding-top: 150px;
  padding-bottom: 100px;
  a {
    color: #212529;
    &:hover {
      .card-img-top {
        filter: sepia(100%);
      }
      .hero-cta2 {
        color: #fff !important;
        background-color: #0098aa !important;
      }
    }
  }
  .card {
    margin: 50px auto;
    float: none;
    .card-link {
      @media (max-width: 991px) {
        display:block;
        margin-left: 0;
      }
      @media (max-width: 767px) {
        display:inline-block;
      }
      @media (max-width: 390px) {
        display:block;
        margin-left: 0 !important;
      }
      +.card-link {
        @media (max-width: 767px) {
          margin-left: 1.25rem;
        }
      }
    }
  }
  .catSearch {
    a {
      &:hover {
        opacity: 0.8;
      }
    }
    .searchSection {
      .card-body {
        text-align: left;
        a {
          margin: 5px 0;
          display: block;
        }
        .form-control {
          margin-bottom: 20px;
        }
      }
      @media (max-width: 767px) {
        display:none;
      }
    }
  }

}
</style>
