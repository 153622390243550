<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col md="auto">
        <div class="blog">
          <h1 class="heroTitle">How does plastic get in to the ocean anyway?</h1>
          <p>by Jesse Reed</p>
          <div class="page-content">
            <p><i>Published on 18 October 2021</i></p>
            <p>According to a report by consultants McKinsey & Company, 260 million metric tons of plastic are thrown away every year, but only 12% of it gets recycled. National Geographic suggests that only about nine percent of all plastic ever created ends up in the recycling bin.
              <br><br>
              We know that plastic pollution is a big problem, and we know that not enough is being done about it, but where is all this plastic coming from? And what can we do to help?
              <br><br>
              It is estimated that as much as 90% of the plastic that ends up in the ocean comes from only 10 rivers.
              <br><br>
              <b>Yangtze River, China</b><br>
              <b>Hai he River, China</b><br>
              <b>Yellow River, China</b><br>
              <b>Mekong River: various countries</b><br>
              <b>Pearl River: China and Vietnam</b><br>
              <b>Indus River: China, India, Pakistan</b><br>
              <b>Ganges River: India and Bangladesh</b><br>
              <b>Amur River: Russia, China</b><br>
              <b>Nile River: multiple countries</b><br>
              <b>Niger River: various countries</b><br>
              <br><br>
              Of these rivers, the biggest contributor to ocean plastic pollution is the Yangtze River. China creates more than 31 million kilograms (68 million pounds) of plastic trash each day, approximately 74 percent of which is not disposed of correctly and can end up as litter that gets swept down storm drains after heavy rains.
              <br><br>
              <b-img src="../../img/blogPics/tips/china-plastic-pollution.jpeg" fluid-grow alt="Fluid-grow image"></b-img>
              Photograph: STR/AFP/Getty Images
              <br><br>
              <p>Every river on the list is a lifeline to some of the poorest people on the planet, so can we really expect them to concern themselves with plastic pollution when they are just trying to survive? The answer is obviously no, so what can we do when these rivers account for 90% of the plastic found in the oceans?
              <br><br>
              Obviously, if you are from a more affluent part of the world than the areas surrounding these 10 rivers, your contribution to plastic pollution might seem a lot less significant. While this is partially correct, by acting as if nothing is wrong, we end up being just as much a part of the problem as someone trying to survive somewhere along the Yangtze River. Actually, in a way, we are a bigger part of the problem because people from wealthier countries are probably the only ones empowered enough to enact real change.
              <br><br>
              The real question is: what can we do? Here are five ways you can make a difference, starting today.
              <br><br>
              1. <b>Use less plastic</b>. It may seem obvious, but there is always more we can do to use less plastic. I know from my own experience that it is easy to forget to take the reusable bag or not buy the plastic-free products from the shop because they're a bit less convenient. It's also less fun to use what we already have that still has life in it rather than buy new at times. I guess we live in a society that constantly tells us to buy plastic, so going against that takes a daily effort.
              <br>
              2. <b>Beach cleanup</b>. I currently live in a seaside town that already has beach cleanup initiatives. It can be a lot of fun to join one or even start one if there isn't one already. A bunch of people on the beach, with some music blasting and a few beers just trying to make the beach a nicer place to be. Sounds perfect to me!
              <br>
              3. <b>Get political</b>. There are actually ways in which we can get involved in policymaking. Many places around the world have enacted bans on single-use plastics, and we can be a part of pushing those bans forward. A great place to learn more about this is <a href="https://www.plasticbaglaws.org/" target="_blank">https://www.plasticbaglaws.org/</a> 
              <br>
              4. <b>Tell your friends</b>. I think that this is a very important part of any social change. I often feel too embarrassed to tell people about my feelings about plastic pollution. I feel like I’m going to be one of those annoying, preachy people that everyone wants to avoid. The truth is, telling people about the problem of plastic pollution and what we can do about it doesn’t have to sound like you’re preaching at them. Simply telling people what you did can often be just as powerful as telling them what you think they should do. “Hey I’m going to a beach cleanup party on Saturday, wanna come?”
              <br>
              5. <b>Recycle</b>. I know it might seem a bit obvious, but recycling can be a bit like a New Year's resolution. We start out great, but after a few weeks, we see the old routines creeping back in. It is a daily effort we need to try our best to keep on top of. I think recycling should be something we do in tandem with using less plastic. The goal is to get more plastic in the recycling bin but have a smaller bag of recycling at the end of the week.
              <br><br>
              Environmental change is a battle. It's like being a fish that's constantly swimming upstream. I believe it’s worth it, though. Even with a small amount of effort, we can help push towards a much less polluted future.
              </p>
              <br>
              <b-button class="hero-cta2" to="/blog" variant="primary">Main blog</b-button>
              <b-button class="hero-cta2" to="/useful-tips-and-ideas-for-sustainable-living" variant="primary">Useful tips & ideas</b-button>
          </div>
          <ul>
            <li>
              <ShareNetwork
              class="socialIcon facebookShare"
              network="facebook"
              url="https://keepusingit.com/how-does-plastic-get-in-to-the-ocean-anyway"
              title="How does plastic get in to the ocean anyway?"
              description="We know that plastic pollution is a big problem, and we know that not enough is being done about it, but where is all this plastic coming from? And what can we do to help?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon twitterShare"
              network="twitter"
              url="https://keepusingit.com/how-does-plastic-get-in-to-the-ocean-anyway"
              title="How does plastic get in to the ocean anyway?"
              description="We know that plastic pollution is a big problem, and we know that not enough is being done about it, but where is all this plastic coming from? And what can we do to help?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon linkedinShare"
              network="linkedin"
              url="https://keepusingit.com/how-does-plastic-get-in-to-the-ocean-anyway"
              title="How does plastic get in to the ocean anyway?"
              description="We know that plastic pollution is a big problem, and we know that not enough is being done about it, but where is all this plastic coming from? And what can we do to help?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon whatsappShare"
              network="whatsapp"
              url="https://keepusingit.com/how-does-plastic-get-in-to-the-ocean-anyway"
              title="How does plastic get in to the ocean anyway?"
              description="We know that plastic pollution is a big problem, and we know that not enough is being done about it, but where is all this plastic coming from? And what can we do to help?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {

}

</script>


<style lang="scss" scoped>
  .col-md-auto {
    width: auto;
    .blog {
      padding: 150px 0 100px 0;
      .page-content {
        padding-top: 30px;
        .hero-cta2 {
          margin-right: 10px !important;
        }
      }
      ul {
        text-align: left;
        padding: 20px 0 0 0;
        li {
          display: inline-block;
          padding-right: 10px;
          .socialIcon {
            width: 50px;
            height: 50px;
            display:block;
            &:hover {
              opacity: 0.8;
            }
          }
          .facebookShare {
            background: url('../../img/icons/facebook-icon-share.png');
            background-size: cover;
          }
          .twitterShare {
            background: url('../../img/icons/twitter-icon-share.png');
            background-size: cover;
          }
          .linkedinShare {
            background: url('../../img/icons/linkedin-icon-share.png');
            background-size: cover;
          }
          .whatsappShare {
            background: url('../../img/icons/whatsapp-icon-share.png');
            background-size: cover;
          }
        }
      }
    }
  }
</style>
