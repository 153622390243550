<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col md="auto">
        <div class="blog">
          <h1 class="heroTitle">Clothes that don't kill fish</h1>
          <p>by Jesse Reed</p>
          <div class="page-content">
            <p><i>Published on 29 January 2021</i></p>
            <p>When I imagine the plastic that is destroying our oceans, I normally think of packaging materials and fishing nets, with perhaps the odd children's doll floating around. I rarely think of clothing, as I don't see the plastic that my clothes contain, and what is unseen often gets forgotten.
              <br><br>
              The Guardian reports that ‘The most comprehensive study to date found the microplastics in 96 of 97 sea water samples taken from across the polar region. More than 92% of the microplastics were fibres, and 73% of these were made of polyester and were the same width and colours as those used in clothes.’
              <br><br>
              When you put clothes containing plastic in the washing machine, some of that plastic inevitably ends up in the water, which inevitably ends up in the sea. These polyester fibers are responsible for injuring marine life that mistakes them for food. Researchers for the journal Nature Communications found 40 microplastic particles per cubic meter of water when studying the Arctic Ocean.
              <br><br>
              When thinking about plastic affecting marine life, we tend to picture turtles and sea birds and often forget about the plankton and small fish that are being affected by microplastics. I guess the question we have to ask ourselves is: What can we do about it? Is there an alternative to our current plastic wardrobe?
              <br><br>
              Vegan fashion brands are becoming more popular as the world gradually awakens to animal cruelty. What many people might not be aware of is that many of these brands are using plastic as an alternative to animal-based materials. So in essence, they save the cows but kill the fish!
              <br><br>
              Thankfully, there are brands out there that are thinking not only about using vegan materials but also about avoiding plastic. I wanted to share a few of them with you that I found not only inspiring in their ethics but also reasonably priced when comparing them to those on the high street.
              <br><br>
              <b-img src="../../img/blogPics/tips/plantBased.jpg" fluid-grow alt="Fluid-grow image"></b-img>
              <br><br>
              First on the list is <a href="https://plantfacedclothing.com/" target="_blank">Plant Faced</a>. They work by the slogan “No beings exploited. Animals, humans or plants” All materials are plastic-free and vegan, including their water-based vegan inks. They sell hoodies for around £43 and t-shirts for around £25.That is not bad for a clear conscience.
              <br><br>
              If you need accessories like a new wallet or belt, check out <a href="https://www.corkor.com/" target="_blank">Corkor</a>. They sell a range of items made from cork. Their products are completely animal and plastic-free, and with their wallets starting at €25, you don’t have to break the bank!
              <br><br>
              <b-img src="../../img/blogPics/tips/ethletic.jpg" fluid-grow alt="Fluid-grow image"></b-img>
              <br><br>
              Finally I want to mention <a href="https://www.ethletic.com/" target="_blank">Ethletic</a> (Great name by the way!). They are a company that specializes in sports shoes. Their products contain no plastic and are made using vegan materials. All of their cotton is fair trade certified, and they use natural rubber harvested from FSC®-certified plantations. At under €100, their shoes are definitely competitive with high-street brands.
              <br><br>
              There are many more brands out there creating great products that don’t harm the environment. I think now more than ever with the internet, we have the chance to find those brands, invest in them, and stop investing in plastic.
              </p>
              <br><br>
              <b-button class="hero-cta2" to="/blog" variant="primary">Main blog</b-button>
              <b-button class="hero-cta2" to="/useful-tips-and-ideas-for-sustainable-living" variant="primary">Useful tips & ideas</b-button>
          </div>
          <ul>
            <li>
              <ShareNetwork
              class="socialIcon facebookShare"
              network="facebook"
              url="https://keepusingit.com/clothes-that-dont-kill-fish"
              title="Clothes that don't kill fish!"
              description="With 40 microplastic particles per cubic metre of water, and much of that coming from our clothes. Here are some vegan and plastic free clothing brands, so we can shop with a clear conscience."
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon twitterShare"
              network="twitter"
              url="https://keepusingit.com/clothes-that-dont-kill-fish"
              title="Clothes that don't kill fish!"
              description="With 40 microplastic particles per cubic metre of water, and much of that coming from our clothes. Here are some vegan and plastic free clothing brands, so we can shop with a clear conscience."
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon linkedinShare"
              network="linkedin"
              url="https://keepusingit.com/clothes-that-dont-kill-fish"
              title="Clothes that don't kill fish!"
              description="With 40 microplastic particles per cubic metre of water, and much of that coming from our clothes. Here are some vegan and plastic free clothing brands, so we can shop with a clear conscience."
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon whatsappShare"
              network="whatsapp"
              url="https://keepusingit.com/clothes-that-dont-kill-fish"
              title="Clothes that don't kill fish!"
              description="With 40 microplastic particles per cubic metre of water, and much of that coming from our clothes. Here are some vegan and plastic free clothing brands, so we can shop with a clear conscience."
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {

}

</script>


<style lang="scss" scoped>
  .col-md-auto {
    width: auto;
    .blog {
      padding: 150px 0 100px 0;
      .page-content {
        padding-top: 30px;
        .hero-cta2 {
          margin-right: 10px !important;
        }
      }
      ul {
        text-align: left;
        padding: 20px 0 0 0;
        li {
          display: inline-block;
          padding-right: 10px;
          .socialIcon {
            width: 50px;
            height: 50px;
            display:block;
            &:hover {
              opacity: 0.8;
            }
          }
          .facebookShare {
            background: url('../../img/icons/facebook-icon-share.png');
            background-size: cover;
          }
          .twitterShare {
            background: url('../../img/icons/twitter-icon-share.png');
            background-size: cover;
          }
          .linkedinShare {
            background: url('../../img/icons/linkedin-icon-share.png');
            background-size: cover;
          }
          .whatsappShare {
            background: url('../../img/icons/whatsapp-icon-share.png');
            background-size: cover;
          }
        }
      }
    }
  }
</style>
