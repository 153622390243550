<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col md="auto">
        <div class="blog">
          <h1 class="heroTitle">What is normal anyway?</h1>
          <p>by Charity Reed</p>
          <div class="page-content">
            <p><i>Published on 19 January 2021</i></p>
            <p>We’ve probably all heard the great news that Covid-19 has been a “green event” with fewer
              planes in the skies, and fewer cars on the roads adding to world pollution. This is fantastic,
              obviously, but I really think it could go on to be so much more with a new attitude to life.
              I was listening to the BBC news channel as I slowly woke up this morning and , as you can
              imagine, there was little being reported other than the latest UK lockdown restrictions. The
              news mongers constantly using the word “normal”, as are we all. Social media, newspapers,
              TV, personal conversations, everyone is talking about what is normal, or “the new normal”,
              and it made me think.
              <br><br>
              Why is it normal to spend more time at work than with those we love? Why is it normal to
              have our children learn more from strangers than from us? Why is it normal to exercise in a
              stuffy indoor gym, rather than outside in the fresh air? Why is it normal to throw a plastic
              wrapped ready meal into the microwave, rather than cook something fresh?
              I’m assuming it’s due to our current infatuation with consumerism. We work more than we
              need, to earn money to buy more stuff that we don’t need, that leaves us less time for family,
              and so on... it’s not even our own desire in many cases, rather just that we go along with
              what society decrees.
              <br><br>
              I’m sure, after all this, we’ll all go straight back to “normal” - but, why not take this time to
              enjoy what, in my opinion, should be “normal”?
              <br><br>
              Let’s take this time to sit down with our kids and watch David Attenborough documentaries,
              teach them about what’s needed to help the environment. Let’s teach them how to plant
              seeds and watch them grow. Let’s take this time to learn how to make jam, how to bake,
              how to sew or knit or crochet, or throw a pot. Let’s write songs and stories, let's dance and
              read and learn a new language. Let’s use our hour outside to run, jog, skip or walk in the
              fresh air, in a park or graveyard, or along the shoreline. Let’s build an obstacle course in the
              garden for the kids, or plant a climbing garden on the balcony, or put out pots in the
              communal areas of our apartment blocks.
              <br><br>
              <b-img src="../../img/blogPics/btcs/woman-child-planting-tree.jpg" fluid-grow alt="Fluid-grow image"></b-img>
              <br><br>
              Whatever we do, let’s do something that leaves, not only some great memories for us and
              our children, but a positive impact (however tiny) on the environment. And most of all, let’s
              embrace what life throws at us, let’s see this as nature telling us to have some fun for a
              change!
              <br><br>
              by Charity Reed</p>
              <b-button class="hero-cta2" to="/blog" variant="primary">Main blog</b-button>
              <b-button class="hero-cta2" to="/behind-the-chicken-shed" variant="primary">Behind the chicken shed</b-button>
          </div>
          <ul>
            <li>
              <ShareNetwork
              class="socialIcon facebookShare"
              network="facebook"
              url="https://keepusingit.com/what-is-normal-anyway"
              title="What is normal anyway?"
              quote="What is normal anyway?"
              description="I’m sure, after all this, we’ll all go straight back to “normal” - but, why not take this time to enjoy what, in my opinion, should be “normal”?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon twitterShare"
              network="twitter"
              url="https://keepusingit.com/what-is-normal-anyway"
              title="What is normal anyway?"
              description="I’m sure, after all this, we’ll all go straight back to “normal” - but, why not take this time to enjoy what, in my opinion, should be “normal”?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon linkedinShare"
              network="linkedin"
              url="https://keepusingit.com/what-is-normal-anyway"
              title="What is normal anyway?"
              description="I’m sure, after all this, we’ll all go straight back to “normal” - but, why not take this time to enjoy what, in my opinion, should be “normal”?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon whatsappShare"
              network="whatsapp"
              url="https://keepusingit.com/what-is-normal-anyway"
              title="What is normal anyway?"
              description="I’m sure, after all this, we’ll all go straight back to “normal” - but, why not take this time to enjoy what, in my opinion, should be “normal”?"
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {

}

</script>


<style lang="scss" scoped>
  .col-md-auto {
    width: auto;
    .blog {
      padding: 150px 0 100px 0;
      .page-content {
        padding-top: 30px;
        .hero-cta2 {
          margin-right: 10px !important;
        }
      }
      ul {
        text-align: left;
        padding: 20px 0 0 0;
        li {
          display: inline-block;
          padding-right: 10px;
          .socialIcon {
            width: 50px;
            height: 50px;
            display:block;
            &:hover {
              opacity: 0.8;
            }
          }
          .facebookShare {
            background: url('../../img/icons/facebook-icon-share.png');
            background-size: cover;
          }
          .twitterShare {
            background: url('../../img/icons/twitter-icon-share.png');
            background-size: cover;
          }
          .linkedinShare {
            background: url('../../img/icons/linkedin-icon-share.png');
            background-size: cover;
          }
          .whatsappShare {
            background: url('../../img/icons/whatsapp-icon-share.png');
            background-size: cover;
          }
        }
      }
    }
  }
</style>
