<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col md="auto">
        <div class="blog">
          <h1 class="heroTitle">A little victory every time</h1>
          <p>by Charity Reed</p>
          <div class="page-content">
            <p><i>Published on 5 October 2020</i></p>
            <p>I’ve always enjoyed recycling old stuff and making it into something better, probably due to my personal place in history and society - that is, growing up in a financially far from affluent family, during the 1970s, before plastics took over the world.
              <br><br>
              A few years ago I was made redundant and had an incredibly meagre income for a year. This necessitated drawing upon the knowledge that I already had, and finding new, innovative ideas to save money. I also realised during those dark days that I was helping the environment and became even more aware, and passionate, about our influence on the planet, and how we can change our behaviour so easily with just a little thought.
              <br><br>
              Firstly, I should probably explain a little about my current living conditions, as I think they have a bearing on how I am able to achieve my lifestyle choices. I hate being in debt so as soon as was possible I sold my house and bought a smaller cottage for less than half the price, thereby becoming mortgage free. This enables me to work part time as I have no rent. I also ensured that the house had an enormous garden so I could grow my own food and bring a few hens into the family. Unfortunately, the smaller house means no room for my, and my partners, hoarding tendancies to grow, as space is at a premium here - we are currently working on improving this by building a large workshop in which to keep all the “crap” that we forage - more on this later.
              <br><br>
              Before we bought the house it had a realatively small cottage garden, so I found the neighbouring farmer’s details online, called him and asked if he’d be willing to sell us an area of his field - thankfully he said yes. However, this meant there was no current physical boundary so my partner had to build one. This he did from entirely recycled wood. The 2m high fence measures roughly 30m by 25m. The wood came from local beauty spots where fly tippers had been busy and other scrapped items that had been dumped from building sites we passed (it’s amazing how many construction companies are grateful for someone taking away even part of their waste free of charge).
              <br><br>
              We also have an amazing greenhouse/shed, again built by my partner. The greenhouse we claimed from freecycle.org (a fantastic website that has local pages in many countries). It had a few panes missing and was an awkward shape so, out of necessity, he built the shed onto the other end of it. The wood for this was reclaimed from a garage that he’d helped dismantle for an elderly gentleman nearby. Wood exchanged for work. He also used secondhand roofing felt to complete the job. The new workshop has been started and, so far, is largely made from reclaimed pallets.</p>
              <br>
              <b-img src="../../img/blogPics/btcs/greenhouse2.jpg" fluid-grow alt="Fluid-grow image"></b-img>
              <br><br>
              <p>The henhouse that was here when we moved in, we used for a couple of years, but we felt it was too lofty for our four little girls, it would get very cold in our bleak Scottish winters, so he built a new smaller one this year which he designed with a lift up roof for easy cleaning. The wood for this was leftover from the aforementioned dismatling of the garage, the roofing felt was also reclaimed (possibly from the neighbour’s shed, but my memory fails me here), and the insulation boards are jigsawed together with bits left from other jobs. Another thing we recycle for the hens is our ridiculously long grass. I cut it down, dry it out and use the hay for sweet smelling bedding. We also always collect any sawdust when my partner gets busy sawing wood - it’s perfect for the floor of the henhouse and costs nothing. (If you don’t have chickens it also makes a great weed suppressant around the garden).</p>
              <br>
              <b-img src="../../img/blogPics/btcs/chicken-shed2.jpg" fluid-grow alt="Fluid-grow image"></b-img>
              <br><br>
              <p>One thing I noticed when I was out of work and wanting to get serious about growing my own vegetables, was how expensive it can be to buy seeds. It cost me more to buy seed potatoes, for example, than a cheap bag of edible potatoes from the supermarket, so now I just put aside any old potatoes for use as seed potatoes ready for when I need them. I also found that storing potatoes for use over the winter could be a problem, so I got some sand from a pile that someone had discarded on public land and layered them with this in an old plastic fuel barrel which stays perfectly cold and dry over the winter months and keeps the potatoes fresh. I also got a lovely crop of peas this year by soaking some dried marrowfat peas from the back of the cupboard and planting them, I did the same with broad beans and now have a lovely stock in the freezer. I grow tomatoes from any that are starting to go mouldy in the fridge, I’ve done the same with peppers, chillis, apples, and even a lemon (although I managed to kill the lemon tree - hey, I may be good with the recycling stuff but I’m no professional gardner!). This year I’ve used an old bag (the kind supermarkets sell french bread sticks in) to hang up my mustard seed pods until they dry out and drop the seeds in the bottom of the bag. I’ve also done this with pak choi, sprouts and broccolli. Incidentally, our raised beds are made from old scaffolding planks.
              <br><br>
              I collect old jars to fill with jam and chutney (I prefer the ones without writing on the lid, obviously, and I’m lucky enough to pick up a particularly fancy looking one from the kitchen at work every week, there’s a good chance you have a local restaurant that would oblige with something similar). Old tins can be useful as seedling pots too - just bang a few tack holes in the bottom and fill them with compost. Old tent poles, foraged from other people’s discarded camp sites, make great garden stakes, and used yoghurt pots are great for cutting up into strips as plant labels. I picked up some great hacks from <a href="https://www.youtube.com/watch?v=3vN5Mn_-5MM" target="_blank">this guy</a>. Another really useful website on sustainable living is <a href="https://thebridgecottageway.co.uk/" target="_blank">The Bridge Cottage Way</a>.
              <br><br>
              One thing I find highly annoying is the amount of plastic we’re unable to recycle, or replace with alternative items. A great example of this is hair dye. What do you do with the plastic applicator bottle after use? Well, I recently discovered that they are great (once thoroughly washed out, of course) for oils, sauces or coulis in the kitchen. It sounds simple but I’ve been reluctantly throwing these bottles away since I started dying my hair years ago! Shopping bags are a really obvious throwaway that most of us hate, and it’s easy enough to buy reuseable ones I know. However, if you’re feeling handy, you can really easily make your own with an old pillow case and a little imagination. My phone has a little case that I made from a ripped t-shirt and padded inside with a piece of scrap felt. It both protects the phone and wipes the screen whenever I slide it in.
              <br><br>
              My partner is great at foraging for old reuseable items too. He once came home with some some old rubber (originally used, we believe, as some kind of belt on an agricultural vehicle), it had been dumped in a layby that he passed whilst walking the dogs. Anyway, he brought it home and I actually laughed, as I could genuinely see no use for it at all. A few days later it had become sturdy mud guards on a cheap old trailer we’d recently bought to carry our kayaks.
              <br><br>
              Our dogs get leftovers too - the local bucher is happy to give us free bones, which we boil up, then mix the broth with rice. They prefer it to dog biscuits and it must be good for them as they’re incredibly healthy. The rice, unfortunately, comes in plastic bags (although they are recycleable, unlike many), but I’ve noticed that 25kg sacks of rice, flour, oats etc. tend to come in strong paper bags, rather than plastic, so they’re a great idea if you can afford them. I should point out though, if you’re likely to encounter mice or other vermin in your home you might want to place the sack into a metal bin, otherwise they’ll chew straight through it!
              <br><br>
              Now, I’ve told you a little about how I live, and I’m aware that most people don’t live in the countryside with a garden big enough for chickens and dogs (most of you would probably hate it - it’s a nightmare cleaning up all the mud for a start!). However, whether you’re in a high rise flat within a cultural hub, a caravan park on the edge of a town, a castle in Bavaria or a council house in Carlise, there are so many things you can do to reuse your old “rubbish”. A few ideas I haven’t mentioned
              <br><br>
              * Plastic milk or juice cartons can be cut in half and used as plant pots.<br>
              * Keep buttons from old shirts, they’ll come in handy one day.<br>
              * Switch to loose tea - even I can’t get teabags to rot down in the compost.<br>
              * Always try to buy eggs in cardboard boxes, I can’t believe some companies are still using plastic but if we all stop buying them, they’ll stop selling them (and if you happen to have eggs in a plastic box at the moment, turn it into a seed tray!).<br>
              * If you have kids, help them turn old tins or jars into pencil holders, money boxes or jewelry containers, as well as teaching the kids to recycle, it will keep them busy decorating them on a wet afteroon. Pringles (other brands are available!) tubes are particularly good for this.<br>
              * Keeping kids in mind, my sister and I had the best fun with old washing up bottles full of water when we were younger (it was before the days of fancy Super Soaker style water guns).<br>
              * If you must replace clothes, crockery, soft furnishings, etc. every season for the sake of fashion, donate the old stuff to charity shops.<br>
              * Reuse old takeaway boxes and ice-cream tubs, rather than throwing them away and buying new plasitc food containers.<br>
              * Where possible, shop local - as well as helping the economy in your area, you’ll find that many small greengrocers and bakers will be happy to sell you produce that’s not pre-packed in plastic bags. Even meat and fish, whilst often in plastic wrapping, won’t come in separate plastic boxes.<br>
              * Tell restaurants you’re grown-up enough to drink a cocktail without a straw! Likewise, let them know if you don’t need sauce sachets, plastic forks or sugar packets with your takeaway purchase.<br>
              * Use old t-shirts as dusters, no need to buy new cloths if you’ve a pile of clean recycled ones in your cupboard.<br>
              * If you have a dog, try to save on food waste by giving them the leftovers - I know it sounds obvious but 90% of dog owners I know don’t seem to do this. I put out, maybe, one full bin liner every three months. It contains no food waste at all so doesn’t smell and it’s because all my food waste goes to dogs, chickens or wild birds (peelings etc. go in the compost).<br>
              * Cook fresh food! It’s healthier, it tastes better and creates very little plastic waste, unlike the ready meal.<br>
              * Wooden clothes pegs are fantastic for resealing bags of food, and they last for years.
              <br><br>
              The fact is, we can’t ever fix the problem on our own, but as well as feeling better knowing that I do what I can, I get a tremendous sense of satisfaction seeing something great come from something discarded - like a little victory every time.
              <br><br>
              by Charity Reed</p>
              <b-button class="hero-cta2" to="/blog" variant="primary">Main blog</b-button>
              <b-button class="hero-cta2" to="/behind-the-chicken-shed" variant="primary">Behind the chicken shed</b-button>
          </div>
          <ul>
            <li>
              <ShareNetwork
              class="socialIcon facebookShare"
              network="facebook"
              url="https://keepusingit.com/a-little-victory-every-time"
              title="A little victory every time"
              quote="A little victory every time"
              description="I’ve always enjoyed recycling old stuff and making it into something better, probably due to my personal place in history and society - that is, growing up in a financially far from affluent family, during the 1970s, before plastics took over the world."
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon twitterShare"
              network="twitter"
              url="https://keepusingit.com/a-little-victory-every-time"
              title="A little victory every time"
              description="I’ve always enjoyed recycling old stuff and making it into something better, probably due to my personal place in history and society - that is, growing up in a financially far from affluent family, during the 1970s, before plastics took over the world."
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon linkedinShare"
              network="linkedin"
              url="https://keepusingit.com/a-little-victory-every-time"
              title="A little victory every time"
              description="I’ve always enjoyed recycling old stuff and making it into something better, probably due to my personal place in history and society - that is, growing up in a financially far from affluent family, during the 1970s, before plastics took over the world."
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
              class="socialIcon whatsappShare"
              network="whatsapp"
              url="https://keepusingit.com/a-little-victory-every-time"
              title="A little victory every time"
              description="I’ve always enjoyed recycling old stuff and making it into something better, probably due to my personal place in history and society - that is, growing up in a financially far from affluent family, during the 1970s, before plastics took over the world."
              hashtags="keepusingit,saynotoplastic"
              >
              </ShareNetwork>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {

}

</script>


<style lang="scss" scoped>
  .col-md-auto {
    width: auto;
    .blog {
      padding: 150px 0 100px 0;
      .page-content {
        padding-top: 30px;
        .hero-cta2 {
          margin-right: 10px !important;
        }
      }
      ul {
        text-align: left;
        padding: 20px 0 0 0;
        li {
          display: inline-block;
          padding-right: 10px;
          .socialIcon {
            width: 50px;
            height: 50px;
            display:block;
            &:hover {
              opacity: 0.8;
            }
          }
          .facebookShare {
            background: url('../../img/icons/facebook-icon-share.png');
            background-size: cover;
          }
          .twitterShare {
            background: url('../../img/icons/twitter-icon-share.png');
            background-size: cover;
          }
          .linkedinShare {
            background: url('../../img/icons/linkedin-icon-share.png');
            background-size: cover;
          }
          .whatsappShare {
            background: url('../../img/icons/whatsapp-icon-share.png');
            background-size: cover;
          }
        }
      }
    }
  }
</style>
