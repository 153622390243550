<template>
  <b-container class="padTandB">
    <b-row>
      <b-col class="about">
        <h1 class="heroTitle">Why keepusingit.com?</h1>
        <p>My name is Jesse Reed and I started keepusingit.com because after over 40 years on this planet, my life was filling up with plastic and starting to overflow.
<br><br>
          It wasn’t always like this. I used to be much better at controlling my consumption of single use plastics, in fact I always felt a bit like an eco warrior. OK part time eco warrior might be more accurate. The point is environmental issues and sustainability have been on my radar for years, but my consumption of plastic was getting worse. Why? kids!
<br><br>
          Since the birth of my son I have faced a tsunami of plastic products. Did you know a baby uses up to 3000 diapers in their first year? and did you know a diaper can take up to 400 years to decompose in a landfill?? The increased number of throw away toys such as those found in kinder eggs is almost overwhelming, causing a constant conflict between wanting your kid to be happy and wanting the earth to be happy.
<br><br>
          I want to become better, learn more, talk more about this problem with other people and become less of a burden on this beautiful planet. That is why I started keepusingit.com. Hopefully through the blog and social media I can create connections, conversations and find solutions to the terrible problem of plastic pollution.
<br><br>
          Connect with me!
        </p>
          <ul>
            <a href="https://www.facebook.com/keepusingit" target="_blank"><li class="facebook"></li></a>
            <a href="https://twitter.com/keepusingit" target="_blank"><li class="twitter"></li></a>
          </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    mounted() {
      document.documentElement.style.scrollBehavior = "unset";
    }
  }
</script>

<style lang="scss" scoped>
  #navBar {
    background-color: rgba(4, 30, 41, 0.9) !important;
  }
  .about {
    ul {
      padding: 0;
      text-align: left;
      li {
        display: inline-block;
        width: 40px;
        height: 40px;
      }
      .facebook {
        background: url('../img/icons/facebook-icon-share.png');
        background-size: cover;
        margin-right: 5px;
        &:hover {
          opacity: 0.8;
        }
      }
      .twitter {
        background: url('../img/icons/twitter-icon-share.png');
        background-size: cover;
        margin-left: 5px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    p {
      padding-top: 50px;
      text-align: left;
    }
  }
</style>
